<template>
  <div class="main-container position-relative py-0 py-md-5">
    <div class="row py-5 justify-content-center">
      <div class="col-12 col-sm-10 col-md-9 col-lg-7 col-xl-6 col-xxl-5">
        <div class="row justify-content-center">
          <div class="col-6 col-sm-5 col-md-4 p-0 text-center mb-2">
            <img
              :src="require('@/assets/images/leprocess_logo_dark.png')"
              class="w-100"
              alt="Le Process logo dark"
            />
          </div>

          <div class="col-11 mb-3">
            <p
              class="text-pink font-bold font-bold font-size-3 mb-4 text-center"
            >
              Dans 1 minute vous êtes de l'autre côté
            </p>
            <p class="text-light mb-2">
              <i class="font-size-5 text-success fas fa-check-circle"></i> 135
              vidéos à la demande, disponibles n'importe quand, de n'importe où.
            </p>
            <p class="text-light mb-2">
              <i class="font-size-5 text-success fas fa-check-circle"></i> 135
              cheat sheets (une par vidéo) pour emporter avec vous les points
              essentiels que vous débloquez au fur et à mesure des vidéos.
            </p>
            <p class="text-light mb-2">
              <i class="font-size-5 text-success fas fa-check-circle"></i> Accès
              au Discord privé LE PROCESS où vos questions sont traitées en
              priorité.
            </p>
            <p class="text-light mb-2">
              <i class="font-size-5 text-success fas fa-check-circle"></i>
              Tableau de bord pour suivre votre avancement.
            </p>
            <p class="text-light mb-2">
              <i class="font-size-5 text-success fas fa-check-circle"></i>
              Le guide ultime pour un entretien de développeur (bonus rentrée
              2021).
            </p>
          </div>

          <div class="col-11 text-center mt-5 mb-4">
            <p class="text-pink font-bold font-bold font-size-3 text-center">
              A quelle adresse email j'envoie vos identifiants ?
            </p>
          </div>
          <div class="col-11 col-sm-9 col-md-8 col-xl-7 text-center mb-5">
            <input
              ref="email"
              class="w-100 text-center py-2 font-size-4"
              v-model="email"
              type="email"
              placeholder="Merci de renseigner votre email ici"
            />
          </div>

          <div class="col-11 col-md-10 my-5">
            <p
              class="text-pink font-bold font-bold font-size-3 mb-4 text-center"
            >
              Choisissez votre moyen de paiement :
            </p>

            <div class="row justify-content-center">
              <div
                class="
                  col-7 col-sm-6 col-md-5
                  center-vertically-flex
                  mb-3 mb-md-0
                "
              >
                <img
                  :src="require('@/assets/images/partially_logo.png')"
                  class="w-100"
                  alt="Partial.ly logo"
                />
              </div>
              <div class="col-10 col-md-7 center-vertically-flex mb-5 mb-md-0">
                <button
                  @click="createPartiallyPayment()"
                  type="button"
                  class="btn btn-lg btn-pink w-100 font-size-3 font-bold"
                >
                  6 x 49€ (sur 6 mois)
                </button>
              </div>
              <div class="w-100 my-2"></div>
              <div
                class="
                  col-7 col-sm-6 col-md-5
                  center-vertically-flex
                  mb-3 mb-md-0
                "
              >
                <div class="row justify-content-center">
                  <div class="col-11">
                    <img
                      :src="require('@/assets/images/paypal_logo.png')"
                      class="w-100"
                      alt="PayPal logo"
                    />
                  </div>
                  <div class="col-12 text-center">
                    <p class="text-light line-height-xs">et carte bancaire</p>
                  </div>
                </div>
              </div>
              <div class="col-10 col-md-7 center-vertically-flex mb-5 mb-md-0">
                <button
                  type="button"
                  class="btn btn-lg btn-dark w-100 font-size-3 font-bold"
                  @click="showPaypalOptions"
                >
                  <p
                    class="line-height-sm"
                    :class="{
                      'text-white-line-through': remainingSpecialOffers > 0,
                    }"
                  >
                    289€ en une fois
                  </p>
                  <p
                    v-if="remainingSpecialOffers > 0"
                    class="line-height-sm"
                    style="white-space: initial"
                  >
                    199€ en une fois
                  </p>
                  <p
                    v-if="remainingSpecialOffers > 0"
                    class="badge badge-red px-4 py-2 rounded"
                    style="font-size: 50% !important"
                  >
                    Promotion rentrée 2021 : {{ remainingSpecialOffers
                    }}{{
                      remainingSpecialOffers > 1 ? " restants" : " restant"
                    }}
                  </p>
                </button>
              </div>
            </div>
          </div>

          <div
            class="col-11 col-md-7 my-4 text-center"
            v-show="paypalOption"
            style="padding-bottom: 30vh !important"
          >
            <p class="text-dark font-size-4 mb-2">
              Veuillez choisir une option :
            </p>
            <div id="paypal-buttons" class="w-100"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  metaInfo() {
    return this.$common.getPageMeta();
  },
  jsonld() {
    return this.$common.getJsonLd();
  },
  data() {
    return {
      email: null,

      paypalOption: false,

      partiallyWindow: null,
      partiallyTrUid: null,

      remainingSpecialOffers: 0,
    };
  },
  methods: {
    showPaypalOptions() {
      if (!this.email || !this.email.length) return this.errorEmail();

      this.paypalOption = true;
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
      }, 500);
    },
    errorEmail(alreadyExist = false) {
      this.emailError = true;
      var message = alreadyExist
        ? "Cette adresse email est déjà utilisée par un autre utilisateur"
        : "Merci de renseigner votre email pour l'envoi des identifiants";

      this.$refs.email.focus();
      return this.$common.showSnackbar(message);
    },
    errorPayment() {
      return this.$common.showSnackbar(
        "Une erreur s'est produite durant le paiement, veuillez réessayer"
      );
    },
    goToPaymentDonePage() {
      var token = this.$common.generateFrontPaymentToken();
      this.$router.push("/payment-done/" + token);
    },
    checkPaymentLoader() {
      this.$common.showPageLoader(
        true,
        "Nous vérifions l'état du paiement, vous allez être redirigé bientôt."
      );
    },
    checkPartiallyTransactionStatus() {
      this.checkPaymentLoader();

      var interationCount = 0;
      var checkInterval = setInterval(() => {
        this.$api.paymentsPartially
          .getStatus(this.partiallyTrUid)
          .then((res) => {
            if (res.data.status == "validated") {
              clearInterval(checkInterval);
              this.$common.hidePageLoader();
              this.goToPaymentDonePage();
            }
          })
          .catch((e) => {});

        interationCount++;
        if (interationCount > 3) {
          clearInterval(checkInterval);
          this.$common.hidePageLoader();
        }
      }, 2500);
    },
    listenStatePartiallyWindow() {
      var openPartiallyUrl = false;
      var interval = setInterval(() => {
        var exist = false;
        try {
          if (!openPartiallyUrl) exist = true;
          console.log(this.partiallyWindow);
        } catch (e) {
          if (!openPartiallyUrl) openPartiallyUrl = true;
          exist = true;
        }

        if (!exist) {
          clearInterval(interval);
          this.partiallyWindow = null;
          this.checkPartiallyTransactionStatus();
        }
      }, 100);
    },
    createPartiallyPayment() {
      if (this.partiallyWindow != null) return;
      if (!this.email || !this.email.length) return this.errorEmail();

      this.$common.showPageLoader(
        false,
        "Le navigateur sécurisé de Partial.ly n'apparaît pas ?\n Attendez un peu ou réessayez dans quelques instants."
      );

      this.partiallyWindow = window.open(
        "",
        "_blank",
        "location=yes,height=570,width=520,scrollbars=yes,status=yes"
      );

      this.$api.paymentsPartially
        .createPayment({
          email: this.email,
        })
        .then((res) => {
          this.partiallyTrUid = res.data.tr_uid;
          this.partiallyWindow.location = res.data.checkout_url;
          this.listenStatePartiallyWindow();
        })
        .catch((e) => {
          this.partiallyWindow.close();
          if (e.response.status == 401 && e.response.data.code == 1001) {
            this.errorEmail(true);
          } else {
            this.errorPayment();
          }
          this.partiallyWindow = null;
          this.$common.hidePageLoader();
        });
    },
    createPaypalOrder(data, actions) {
      if (!this.email || !this.email.length) return this.errorEmail();

      setTimeout(() => {
        //fix paypal loader delay
        this.$common.showPageLoader(true);
      }, 200);

      return this.$api.paymentsPaypal
        .createOrder({
          email: this.email,
          discountID: this.remainingSpecialOffers > 0 ? "rentree2021" : "",
        })
        .then((res) => {
          this.$common.hidePageLoader();
          return res.data.id;
        })
        .catch((e) => {
          if (e.response.status == 401 && e.response.data.code == 1001) {
            this.errorEmail(true);
          } else {
            this.errorPayment();
          }
          this.$common.hidePageLoader();
          return;
        });
    },
    onPaypalApprove(data, actions) {
      this.checkPaymentLoader();
      return this.$api.paymentsPaypal
        .captureOrder(data.orderID)
        .then((res) => {
          this.$common.hidePageLoader();
          this.goToPaymentDonePage();
        })
        .catch((e) => {
          this.errorPayment();
          return;
        });
    },
    configurePaypal() {
      paypal
        .Buttons({
          style: {
            layout: "vertical",
            label: "paypal",
          },
          createOrder: this.createPaypalOrder,
          onApprove: this.onPaypalApprove,
        })
        .render("#paypal-buttons");
    },
    getSpecialOfferCount() {
      this.$api.discounts.getRentree2021Count().then((res) => {
        this.remainingSpecialOffers = res.data.count;
      });
    },
  },
  created: function () {
    this.getSpecialOfferCount();
  },
  mounted: function () {
    this.configurePaypal();
  },
  destroyed() {},
};
</script>
